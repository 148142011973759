<template>
  <v-row>
    <v-col cols="12" md="4">
      <h3>{{ router.routerLeft.title }}</h3>
      <p class="mt-3">
        {{ router.routerLeft.des }}
      </p>
      <!-- <v-btn outlined color="black" class="px-13 py-5 btn-notification" @click="redirect(router.routerLeft.router)">
        <span class="font-weight-bold text">{{ router.routerLeft.button }}</span>
      </v-btn> -->
    </v-col>
    <v-col cols="12" md="8" class="py-0">
      <v-row v-for="(item, index) in router.routerRight" :key="index">
        <v-col cols="12" class="d-flex align-center">
          <notification-group :router="item" :id="router.id" />
        </v-col>
        <v-col cols="12" class="d-flex align-center" v-if="router.id === 1">
          <Recipient />
        </v-col>
      </v-row>
    </v-col>
    <modal
      model="modal"
      title="Add an order notification"
      titleButton="Add an order notification"
      :callback="submit"
      width="650px"
      class="py-16"
    >
      <template slot="content">
        <div>
          <h3>Notification method</h3>
          <v-select v-model.trim="method" :items="emailMethod" color="primary" required></v-select>
        </div>
        <div :class="{ 'd-none': !isOpenEmail }">
          <h3>Email address</h3>
          <v-text-field
            placeholder="Please input"
            v-model.trim="$v.email.$model"
            :error-messages="error"
            @input="checkValidateWithBlurInput"
          >
          </v-text-field>
        </div>
      </template>
    </modal>
  </v-row>
</template>

<script>
import NotificationGroup from './NotificationGroup';
import Recipient from './Recipient';
import { required, email } from 'vuelidate/lib/validators';
export default {
  components: {
    NotificationGroup,
    Recipient,
  },
  props: {
    router: Object,
  },
  data() {
    return {
      modal: false,
      emailMethod: ['Email address...', 'email@gmail.com'],
      email: '',
      method: 'Email address...',
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    checkValidateWithBlurInput() {
      this.$v.email.$touch();
    },
    submit() {
      this.modal = false;
    },
    redirect(router) {
      if (router === 'website.settings.notifications.recipient') {
        this.modal = true;
      } else {
        this.$router.push({ name: `${router}` });
      }
    },
  },
  computed: {
    error() {
      let errorMessage = '';
      if (this.$v.email.$dirty && this.$v.email.$invalid) {
        if (!this.$v.email.required) {
          errorMessage = 'Email is required';
        }
        if (!this.$v.email.email) {
          errorMessage = 'Invalid email';
        }
      }
      return errorMessage;
    },
    isOpenEmail() {
      if (this.method === 'Email address...') {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.btn-notification:hover .text {
  text-decoration: underline;
}
</style>

<template>
  <v-row class="elevation-form px-5 py-5">
    <v-col cols="12">
      <h3>Recipients</h3>
    </v-col>
    <v-col class="py-0 px-0 mx-0">
      <v-divider></v-divider>
      <v-row>
        <v-col cols="5">
          <span :class="{ 'line-through': !isActive }">Send email to " </span>
          <span :class="{ 'line-through': !isActive }">shopname</span>
          <span :class="{ 'line-through': !isActive }"> " </span>
          <span :class="{ 'line-through': !isActive }">email.gmail.com</span>
        </v-col>
        <v-col cols="7" class="text-right">
          <span class="mx-1 router-title">Send test notification</span>
          <v-btn outlined class="mx-1" color="primary" @click="isActive = !isActive" min-width="100px">
            <span class="font-weight-bold text-black" v-if="isActive">Disable</span>
            <span class="font-weight-bold text-black" v-if="!isActive">Enable</span>
          </v-btn>
          <v-btn outlined class="" @click="modal = true" width="40px" color="primary" min-width="40px">
            <v-icon size="14px" color="black">fas fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <modal
      model="modal"
      title="Are you sure you want to delete this order notification?"
      titleButton="Delete"
      :callback="submitDelete"
      width="650px"
    >
      <template slot="content">
        <span>Once you delete it, you won’t be able to undo this action.</span>
      </template>
    </modal>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      isActive: false,
    };
  },
  methods: {
    submitDelete() {
      this.modal = false;
    },
  },
};
</script>

<style lang="scss">
.router-title {
  color: #a98a57;
}
.router-title:hover {
  cursor: pointer;
}
.line-through {
  text-decoration: line-through;
}
.text-black {
  color: black;
}
.v-size--default {
  height: 40px;
  min-width: 0px;
}
</style>

<template>
  <div class="notificatons__page">
    <Redirect class="" title="Settings" route="website.settings" />
    <v-col cols="12" class="page-header">
      <h2 class="page-title">Notifications</h2>
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12" class="mt-5" v-for="router in listRouter" :key="router.id">
      <notification-type :router="router" />
      <v-divider class="mt-16" v-if="router.id + 1 < listRouter.length"></v-divider>
    </v-col>
  </div>
</template>

<script>
import Redirect from '@/components/RedirectTo';
import NotificationType from './components/NotificationType';

export default {
  components: {
    Redirect,
    NotificationType,
  },
  data() {
    return {
      listRouter: [
        {
          id: 0,
          routerLeft: {
            title: ' Customer notifications ',
            des:
              ' These notifications are automatically sent out to the customer. Click on the notification template to edit the content. ',
            button: ' Customize ',
            router: 'website.settings.notifications.customize',
          },
          routerRight: [
            {
              title: ' Order ',
              router: [
                {
                  title: ' Order confirmation ',
                  des: ' Sent automatically to the customer after they place their order. ',
                  router: 'website.settings.notifications.order.confirmation',
                  subtitle: '',
                },
                {
                  title: ' Order canceled ',
                  des: ' Sent automatically to the customer if their order is canceled (if you select this option). ',
                  router: 'website.settings.notifications.order.canceled',
                  subtitle: '',
                },
                {
                  title: ' Order refund ',
                  des: ' Sent automatically to the customer if their order is refunded (if you select this option). ',
                  router: 'website.settings.notifications.order.refund',
                  subtitle: '',
                },
                {
                  title: ' Abandoned checkout - First email ',
                  des:
                    ' Sent to the customer to remind them about the items they left in their cart. Configure options in ',
                  router: 'website.settings.notifications.order.abandoned.checkout.email.1',
                  subtitle: ' Abandoned checkouts ',
                },
                {
                  title: ' Abandoned checkout - Second email ',
                  des:
                    ' Sent the third email to the customer to remind them about the items they left in their cart. Configure options in ',
                  router: 'website.settings.notifications.order.abandoned.checkout.email.2',
                  subtitle: ' Abandoned checkouts ',
                },
                {
                  title: ' Abandoned checkout - Third email ',
                  des: ' Sent automatically to the customer after they place their order. ',
                  router: 'website.settings.notifications.order.abandoned.checkout.email.3',
                  subtitle: ' Abandoned checkouts ',
                },
              ],
            },
            {
              title: ' Shipping ',
              router: [
                {
                  title: ' Fulfillment request ',
                  des:
                    ' Sent automatically to a third-party fulfillment service provider when order items are fulfilled. ',
                  router: 'website.settings.notifications.shipping.fulfillment.request',
                  subtitle: '',
                },
                {
                  title: ' Shipping confirmation ',
                  des:
                    ' Sent automatically to the customer if their fulfilled order’s tracking number is updated (if you select this option). ',
                  router: 'website.settings.notifications.shipping.shipping.confirmation',
                  subtitle: '',
                },
                {
                  title: ' Shipping update ',
                  des:
                    ' Sent automatically to the customer after the status of their fulfillment changes to out for delivery. ',
                  router: 'website.settings.notifications.shipping.shipping.update',
                  subtitle: '',
                },
                {
                  title: ' Shipment out for delivery ',
                  des:
                    ' Sent automatically to the customer after the status of their fulfillment changes to out for delivery. ',
                  router: 'website.settings.notifications.shipping.shipping.delivery',
                  subtitle: '',
                },
                {
                  title: ' Shipment delivered ',
                  des:
                    ' Sent automatically to the customer after the status of their fulfillment changes to delivered. ',
                  router: 'website.settings.notifications.shipping.shipping.delivered',
                  subtitle: '',
                },
              ],
            },
            {
              title: ' Customers ',
              router: [
                {
                  title: ' Customer account activation ',
                  des:
                    ' Sent to the customer with account activation instructions. You can edit this email before you send it. ',
                  router: 'website.settings.notifications.customer.customer.account.activation',
                  subtitle: '',
                },
                {
                  title: ' Customer account welcome ',
                  des: ' Sent automatically to the customer when they complete their account activation. ',
                  router: 'website.settings.notifications.customer.customer.account.welcome',
                  subtitle: '',
                },
                {
                  title: ' Customer account password reset ',
                  des: ' Sent automatically to the customer when they ask to reset their accounts password. ',
                  router: 'website.settings.notifications.customer.customer.account.password.reset',
                  subtitle: '',
                },
              ],
            },
            {
              title: ' Review ',
              router: [
                {
                  title: ' Review Request ',
                  des:
                    ' Sent to the customer with account activation instructions. You can edit this email before you send it. ',
                  router: 'website.settings.notifications.review.review.request',
                  subtitle: 'Review request',
                },
                {
                  title: ' Review email ',
                  des:
                    ' Sent to the customer when their order is completed. You can edit this email before you send it. Configure options in ',
                  router: 'website.settings.notifications.review.review.remind',
                  subtitle: 'Review email',
                },
                {
                  title: ' Customer account password reset ',
                  des:
                    ' Sent to the customer if you have not received a product review yet. You can edit this email before you send it. Configure options in ',
                  router: '',
                  subtitle: '',
                },
              ],
            },
            {
              title: 'After sale ',
              router: [
                {
                  title: 'After sale Email 1',
                  des: ' Sent to the customer after 1 day email template. You can edit this email before you send it. ',
                  router: 'website.settings.notifications.aftersale.email1',
                  subtitle: 'Email 1',
                },
                {
                  title: 'After sale Email 2',
                  des: ' Sent to the customer after 2 day email template. You can edit this email before you send it. ',
                  router: 'website.settings.notifications.aftersale.email2',
                  subtitle: 'Email 2',
                },
              ],
            },
          ],
        },
        // {
        //   id: 1,
        //   routerLeft: {
        //     title: ' Staff order notifications ',
        //     des: ' Choose how you want to be notified when a new order comes in or add other recipients. ',
        //     button: ' Add Recipient ',
        //     router: 'website.settings.notifications.recipient',
        //   },
        //   routerRight: [
        //     {
        //       title: ' Templates ',
        //       router: [
        //         {
        //           title: ' New order ',
        //           des: ' Sent to order notification subscribers when a customer places an order. ',
        //           router: 'website.settings.notifications.staff.new.order',
        //           subtitle: '',
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    };
  },
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped></style>
